const buildProfileInfoObject = (queryData, uData) => {
  const dataObject = {
    qdbapi: {
      udata: uData,
      apptoken: "d8phrhgbq4mmfwc3rkhnddciskd3",
      QueryString: queryData,
      fmt: "structured"
    }
  };
  if (queryData) {
    dataObject.qdbapi.query = `{'6'.EX.'${queryData}'}`;
  }
  return dataObject;
};

export default buildProfileInfoObject;

<template>
  <div class="home">
    <div class="container">
      <div class="columns is-centered">
        <div class="column">
          <div class="content">
            <br />
            <h1>Apply</h1>
            <br />
            <p>
              Below you’ll find the two steps to apply for the Rental
              Improvement Fund.
            </p>
            <ul>
              <li>
                First, register for an upcoming information session:
                <a
                  href="https://rental-improvement.phdcphila.org/#/InformationSessions"
                  >https://rental-improvement.phdcphila.org/#/InformationSessions</a
                >.
              </li>
              <li>
                Second, complete the brief eligibility screener to confirm your
                eligibility.
              </li>
              <li>
                If you’re eligible based on your responses to the screener, the
                full application will load. Please have all the necessary
                information on hand before you begin the application.
              </li>
            </ul>

            <h3>Eligibility Screener</h3>
            <callout type="info">
              Please complete the basic eligibility screener below. To be
              eligible, you must be able to answer ‘yes’ to each of the
              questions below. If your answer to each of the questions is ‘yes’,
              the program intake form will appear at the bottom of your screen.
            </callout>
            <br />
            <div class="field-container program-qualifications">
              <div class="columns">
                <div class="column">
                  <radio
                    v-model="InCityOfPhiladelphia"
                    :options="radioOptions.YesNo"
                    label="This is a loan program for rental properties in Philadelphia. Do you own one or more rental properties within the City of Philadelphia?"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <textbox
                    v-model="AmountOfProperties"
                    placeholder="How many rental properties do you own, fully and/or partially under your name or an LLC? Please enter a whole number (e.g. 3)"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <textbox
                    v-model="AmountOfUnits"
                    placeholder="How many rental units do you own, fully and/or partially under your name or an LLC? Please enter a whole number (e.g. 3)"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <radio
                    v-model="Taxes"
                    :options="radioOptions.YesNo"
                    label="Are you current or in a payment agreement for both your property taxes and your revenue tax?"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <radio
                    v-model="Occupied"
                    :options="radioOptions.YesNo"
                    label="Is the property for which you're applying for a RIF loan currently occupied by tenants?"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <radio
                    v-model="RentalLicense"
                    :options="radioOptions.YesNo"
                    label="Does the property for which you're applying for a RIF loan have an active rental license?"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <radio
                    v-model="AffordableRent"
                    :options="radioOptions.YesNo"
                    label="Are the rental units at the property for which you want a loan rented at or below the monthly rent amounts listed in the table below?"
                  />
                </div>
              </div>
              <thead>
                <th>
                  Maximum Monthly Rent by Number of Bedrooms
                </th>
              </thead>
              <table>
                <tr>
                  <th># of bedrooms</th>
                  <th>0 (studio)</th>
                  <th>1</th>
                  <th>2</th>
                  <th>3</th>
                  <th>4</th>
                  <th>5</th>
                  <th>6</th>
                </tr>
                <tr>
                  <td>
                    Monthly Rent
                  </td>
                  <td>
                    $1,204
                  </td>
                  <td>
                    $1,290
                  </td>
                  <td>
                    $1,549
                  </td>
                  <td>
                    $1,789
                  </td>
                  <td>
                    $1,996
                  </td>
                  <td>
                    $2,203
                  </td>
                  <td>
                    $2,409
                  </td>
                </tr>
              </table>

              <div class="columns">
                <div class="column">
                  <radio
                    v-model="InfoSession"
                    :options="radioOptions.YesNo"
                    label="Have you registered for or attended a Rental Improvement Fund information session?"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column" v-if="InfoSession == 'No'">
                  <callout type="info">
                    Please register here:
                    <a
                      href="https://rental-improvement.phdcphila.org/#/InformationSessions"
                      >https://rental-improvement.phdcphila.org/#/InformationSessions</a
                    >
                  </callout>
                </div>
              </div>

              <!-- <p>
                Please enter the unit size and monthly rent for the unit that
                you would be repairing through the Rental Improvement Fund.
              </p>
              <div class="columns">
                <div class="column">
                  <dropdown
                    v-model="UnitSize"
                    :options="UnitSizes"
                    name="input-unitSize"
                    placeholder="Select Unit Size"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <textbox
                    v-model="MonthlyRent"
                    name="input-monthlyRent"
                    placeholder="What is the monthly rent? Please type a number without a dollar sign ($)."
                  />
                </div>
              </div> -->

              <div class="columns">
                <div
                  class="column"
                  v-if="
                    InCityOfPhiladelphia === 'No' ||
                      ((AmountOfProperties < 1 || AmountOfProperties > 5) &&
                        AmountOfProperties != null) ||
                      ((AmountOfUnits < 1 || AmountOfUnits > 15) &&
                        AmountOfUnits != null) ||
                      Taxes === 'No' ||
                      AffordableRent === 'No' ||
                      (Occupied === 'No' && RentalLicense === 'No')
                  "
                >
                  <callout type="warning">
                    Based on your responses above, you are not eligible for the
                    Rental Improvement Fund. For more information about
                    eligibility, please visit the program page on PHDC’s
                    website:
                    <a
                      href="https://phdcphila.org/residents-and-landlords/landlords/rental-improvement-fund/"
                      >https://phdcphila.org/residents-and-landlords/landlords/rental-improvement-fund/</a
                    >.
                  </callout>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="columns is-centered">
        <div class="column">
          <div class="content">
            <div
              v-if="
                InCityOfPhiladelphia === 'Yes' &&
                  AmountOfProperties >= 1 &&
                  AmountOfProperties <= 5 &&
                  AmountOfUnits >= 1 &&
                  AmountOfUnits <= 15 &&
                  Taxes === 'Yes' &&
                  AffordableRent === 'Yes' &&
                  ((Occupied === 'Yes' && RentalLicense === 'Yes') ||
                    (Occupied === 'Yes' && RentalLicense === 'No') ||
                    (Occupied === 'No' && RentalLicense === 'Yes'))
              "
            >
              <callout type="warning">
                <p>
                  <b>
                    Based on your responses above, you appear to be eligible for
                    the program.
                  </b>
                  In order to save time, we recommend you gather electronic
                  copies or images of the following documents before you proceed
                  to the application. When you are ready to apply, please
                  proceed by clicking the button below. Please note that you
                  should complete a single application even if you are applying
                  for multiple RIF loans to do repairs to multiple rental
                  properties.
                </p>
                <ol>
                  <li>
                    Current lease for occupied unit(s) that will benefit from
                    proposed repairs.
                  </li>
                  <li>
                    Tenant Permission Form, completed and signed by both you and
                    the tenant(s) living in the unit(s) to be repaired.
                    <a href="https://bit.ly/rif-tenant-notice"
                      >You can download a copy here</a
                    >. You may print the form and sign it in ink or use Adobe
                    Fill & Sign to add electronic signatures.
                  </li>
                  <li>
                    For Loan Option 2 ($50k/15-year term), current tenants
                    living in units that will benefit from repairs must complete
                    and submit a tenant household income attestation to PHDC.
                    <a
                      href="https://phdcphila.org/resources/tenant-household-income-attestation/"
                      >You can download a copy to give to your tenant(s) here</a
                    >.
                  </li>
                  <li>
                    Scope of work from a licensed contractor with itemized cost
                    estimate for repairs.
                  </li>
                  <li>
                    Proof of Funds. We require that 20% of the loan be set aside
                    for contingencies. If you choose Loan Option 1 and your
                    estimated repair cost exceeds $20,800 or Loan Option 2
                    exceeds $41,650, will be required to show proof of funds to
                    start the repair work. Please read the FAQ for more
                    information.
                  </li>
                </ol>
                <p>
                  The Mortgage Loan Processor assigned to your application may
                  request other required documents after your online application
                  is submitted. We will generally reach out by email, so check
                  your inbox and spam or junk folder.
                </p>
              </callout>
              <div class="column is-2 is-offset-10">
                <a class="button is-primary" @click="gotoLandlordProfile"
                  >Proceed</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import options from "@/utilities/options.js";
import { Callout } from "@phila/phila-ui";
export default {
  name: "Home",
  mixins: [options],
  //components: { FormstackApp }
  data() {
    return {
      InCityOfPhiladelphia: null,
      AmountOfProperties: null,
      AmountOfUnits: null,
      Taxes: null,
      AffordableRent: null,
      InfoSession: null,
      Occupied: null,
      RentalLicense: null
    };
  },
  components: { Callout },
  methods: {
    gotoLandlordProfile() {
      this.$router.push({
        name: "LandlordProfile"
      });
    }
  },
  computed: {
    // Calculates the maximum rend based on the number of rooms
    maximumRent: function() {
      if (this.UnitSize == "0 Bedrooms (studio)") {
        return 1107;
      }
      if (this.UnitSize == "1 Bedroom") {
        return 1186;
      }
      if (this.UnitSize == "2 Bedrooms") {
        return 1423;
      }
      if (this.UnitSize == "3 Bedrooms") {
        return 1644;
      }
      if (this.UnitSize == "4 Bedrooms") {
        return 1834;
      }
      if (this.UnitSize == "5 Bedrooms") {
        return 2024;
      }
      if (this.UnitSize == "6 Bedrooms") {
        return 2214;
      } else {
        return -1;
      }
    }
  },
  mounted() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
};
</script>

<style lang="scss" scoped>
.columns,
.column,
.content div {
  margin-bottom: 0;
}
.address-label {
  padding-left: 5px;
}
</style>

<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column">
        <div class="content">
          <ValidationObserver v-slot="{ errors }" tag="div">
            <input-form
              ref="form"
              :errors="errors"
              name="HelpDeskFrm"
              @submit.prevent="handleSubmit"
            >
              <br />
              <h3>Rental Improvement Fund - Inspection Request</h3>
              <div class="columns">
                <div class="column">
                  <vee-textbox
                    v-model="FirstName"
                    name="input-first-name"
                    placeholder="Enter your First Name"
                    type="text"
                  />
                </div>
                <div class="column">
                  <vee-textbox
                    v-model="LastName"
                    name="input-last-name"
                    placeholder="Enter your Last Name"
                    type="text"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <vee-textbox
                    v-model="PhoneNumber"
                    name="input-phoneNumber"
                    placeholder="Enter your Phone Number"
                    rules="phone"
                  />
                </div>
                <div class="column">
                  <vee-textbox
                    v-model="EmailAddress"
                    name="input-emailAddress"
                    placeholder="Enter your Email"
                    rules="email"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <vee-dropdown
                    v-model="PreferredMethodOfContact"
                    :options="ContactMethods"
                    name="input-contactMethod"
                    placeholder="Select Preferred Method of Contact"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <vee-dropdown
                    v-model="InspectionRequestType"
                    :options="InspectionRequestTypes"
                    name="input-inspection-requestType"
                    placeholder="Select Inspection Request Type"
                    rules="required"
                    required
                    error="This field is required"
                  />
                </div>
              </div>
              <div class="columns">
                <div style="margin-left: 0.5%;" class="column">
                  <br />
                  <input
                    id="deductionCheck"
                    v-model="DeductionCheck"
                    style="width: 7%; height: 7%; margin-left: -3%;"
                    true-value="1"
                    false-value="0"
                    type="checkbox"
                  />
                  <label for="deductionCheck" style="margin-left: -2.75%;">
                    By checking this box, I acknowledge and confirm the 6 items
                    listed below:*
                    <br />
                    <br />

                    <p>
                      1. I understand the PHDC inspector must be able to access
                      the entire property at the time of the scheduled
                      inspection, including areas that are not being repaired.
                    </p>
                    <p>
                      2. I understand I must be present at the time of the
                      inspection or send the general contractor in my place.
                    </p>
                    <p>
                      3. I agree to notify all tenants living at the property of
                      the date and time of the inspection.
                    </p>
                    <p>
                      4. I agree to provide the inspector access to the entire
                      property, including all units, basement, and back yard.
                    </p>
                    <p>
                      5. If the inspector cannot access the entire property or
                      if the property does not pass the inspection, I agree to
                      pay a $200 reinspection fee.
                    </p>
                    <p>
                      6. If I cancel the inspection with less than one business
                      day’s notice, I agree to pay a $200 reinspection fee.
                    </p>
                  </label>
                </div>
              </div>
              <!--<div class="columns">
                <div class="column">
                  <vee-textarea
                    v-model="Message"
                    name="input-message"
                    placeholder="Is there anything we should know?"
                  />
                </div>
              </div>-->
              <div>
                <div class="column is-2 is-offset-10">
                  <button
                    type="button"
                    :disabled="
                      SubmitStatus.Value === 'PENDING' ||
                        SubmitStatus.Value === 'OK' ||
                        DeductionCheck == false
                    "
                    class="button is-primary"
                    @click="handleSubmit"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div class="column">
                <div v-if="SubmitStatus.Value === SubmitStatus.Options.PENDING">
                  Submitting...
                </div>
                <div v-if="SubmitStatus.Value === SubmitStatus.Options.OK">
                  OK
                </div>
                <div v-if="SubmitStatus.Value === SubmitStatus.Options.ERROR">
                  Error
                </div>
              </div>
            </input-form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import options from "@/utilities/options.js";
import buildHelpDeskSubmissionDataObject from "@/utilities/data-object.js";
import axios from "axios";
import { dataObjectToXml } from "@/utilities/functions.js";
export default {
  name: "HelpDesk",
  mixins: [options],
  props: {
    loanId: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      FirstName: null,
      LastName: null,
      EmailAddress: null,
      RequestType: null,
      Message: null,
      PhoneNumber: null,
      PreferredMethodOfContact: null,
      InspectionRequestType: null,
      DeductionCheck: 0
    };
  },
  methods: {
    // Submits the users information to QuickBase
    async handleSubmit() {
      var vData = this;
      vData.SubmitStatus.Value = vData.SubmitStatus.Options.PENDING;
      const isInputValid = await vData.$refs.form.$parent.validate();
      if (isInputValid) {
        const dataObject = buildHelpDeskSubmissionDataObject(vData);
        const xmlDataObject = dataObjectToXml(dataObject);
        const requestObject = {
          table: "bs2nh9nki",
          action: "API_AddRecord",
          data: xmlDataObject
        };

        axios
          .post(
            "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Rental-Improvement-Fund-QuickBase-Request-Proxy",
            requestObject
          )
          .then(response => {
            console.log(response);
            vData.SubmitStatus.Value = vData.SubmitStatus.Options.OK;
            vData.$router.push({
              name: "SuccessInspection"
            });
          })
          .catch(() => {
            vData.SubmitStatus.Value = vData.SubmitStatus.Options.ERROR;
          });
      } else {
        vData.SubmitStatus.Value = vData.SubmitStatus.Options.ERROR;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.columns,
.column,
.content div {
  margin-bottom: 0;
}
.address-label {
  padding-left: 5px;
}
</style>

<template>
  <ValidationObserver v-slot="{ errors }" tag="div">
    <input-form
      ref="form"
      :errors="errors"
      name="StatusFrm"
      @submit.prevent="handleSubmit"
    >
      <div class="content">
        <br />
        <h3>Rental Improvement Fund - Check Application Status</h3>
        <br />
        <div class="container">
          <div class="columns">
            <div class="column">
              <callout type="info"
                >Use the form below to view the status of your loan
                application(s) and take any required actions to move your
                loan(s) forward. You’ll need to enter the same email address you
                used to create your RIF Landlord Profile, and the verification
                code that was sent to you when you created your profile.
              </callout>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="columns">
            <div class="column">
              <vee-textbox
                v-model="emailID"
                error="This field is required for the status check"
                name="input-email"
                required
                rules="required_status"
                placeholder="Enter your Email"
                type="text"
              />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <vee-textbox
                v-model="verificationCode"
                name="input-verification-code"
                required
                error="This field is required for the status check"
                rules="required_status"
                placeholder="Enter your verification code"
                type="text"
              />
            </div>
          </div>

          <div class="column is-2 is-offset-10">
            <button
              type="button"
              class="button is-primary"
              @click="handleSubmit"
            >
              Enter
            </button>
          </div>
          <div class="column" id="page"></div>
        </div>
      </div>
    </input-form>
  </ValidationObserver>
</template>

<script>
import axios from "axios";
import buildLoanCheckObject from "@/utilities/get-loan-check-object.js";
import { getPropertyValueOrDefault } from "@/utilities/functions.js";

export default {
  name: "LoanDetails",
  data() {
    return {
      emailID: null,
      verificationCode: null,
      loanResult: null,
      newLoanURL: null
    };
  },
  created() {},
  methods: {
    async handleSubmit() {
      document.getElementById("page").innerHTML = "";
      var vData = this;
      const isInputValid = await vData.$refs.form.$parent.validate();
      if (isInputValid) {
        const dataObject = buildLoanCheckObject(
          vData.emailID,
          vData.verificationCode,
          "RIF_GET_LOAN_INFO"
        );
        const requestObj = {
          table: "bs2bx26ex",
          data: dataObject
        };
        axios
          .post(
            "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Rental-Improvement-Fund-Quickbase-Request-Proxy-Reads",
            requestObj
          )
          .then(response => {
            var page = document.getElementById("page");
            const responseObject = getPropertyValueOrDefault(
              ["data", "record", "f"],
              response
            );
            if (responseObject != null) {
              vData.loanResult = getPropertyValueOrDefault(
                ["_text"],
                responseObject.find(i => i._attributes.id === "325")
              );
              vData.newLoanURL = getPropertyValueOrDefault(
                ["_text"],
                responseObject.find(i => i._attributes.id === "313")
              );

              var loansList = vData.loanResult.split('";"');
              loansList[0] = loansList[0].substring(1);
              loansList[loansList.length - 1] = loansList[
                loansList.length - 1
              ].substring(0, loansList[loansList.length - 1].length - 1);

              for (var i = 0; i < loansList.length; i++) {
                var button = document.createElement("button");
                button.type = "button";
                button.className = "collapsible2";
                var RIFIdIndex = loansList[i].indexOf("Address:") + 13;
                var RIFIdIndexEnd = loansList[i].indexOf(
                  "</p><p><b>Loan Product:"
                );
                button.innerText =
                  loansList[i].substring(RIFIdIndex, RIFIdIndexEnd) + "▼";
                page.append(button);
                var content2 = document.createElement("div");
                content2.className = "content2";
                content2.innerHTML = loansList[i];
                page.append(content2);
                var linebreak = document.createElement("div");
                linebreak.innerHTML = "<br>";
                page.append(linebreak);
              }

              page.innerHTML +=
                "<br /><a style='background-color: #25cef7; border: none; color: white; padding: 10px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer;  border-radius: 6px' href=" +
                vData.newLoanURL +
                " target='_blank'>Submit a new loan request</a><br />";

              this.startUp();
            } else {
              page.innerHTML = "<p>Record Not Found.</p>";
              page.innerHTML +=
                "<br /><a style='background-color: #25cef7; border: none; color: white; padding: 10px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer;  border-radius: 6px' href=" +
                vData.newLoanURL +
                " target='_blank'>Submit a new loan request</a><br />";
            }
          })
          .catch(function(error) {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
            var page = document.getElementById("page");
            page.innerHTML = "<p>Request Timed Out. Please Try Again.</p>";
            page.innerHTML +=
              "<br /><a style='background-color: #25cef7; border: none; color: white; padding: 10px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer;  border-radius: 6px' href=" +
              vData.newLoanURL +
              " target='_blank'>Submit a new loan request</a><br />";
          });
      }
    },
    startUp() {
      var coll = document.getElementsByClassName("collapsible2");
      var i;
      for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function() {
          this.classList.toggle("active2");
          var str = this.innerText;
          if (str.includes("▼")) {
            str = str.substring(0, str.length - 1);
            str = str + "▶";
            this.innerText = str;
          } else if (str.includes("▶")) {
            str = str.substring(0, str.length - 1);
            str = str + "▼";
            this.innerText = str;
          }
          var content2 = this.nextElementSibling;
          if (content2.style.display === "block") {
            content2.style.display = "none";
          } else {
            content2.style.display = "block";
          }
        });
      }
    }
  }
};
</script>

<style>
.collapsible2 {
  background-color: hsl(211.2, 81.1%, 31.2%);
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.active2,
.collapsible2:hover {
  background-color: hsl(211.2, 81.1%, 31.2%);
}

.content2 {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
}
</style>
